import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import { Grid, styled } from '@mui/material'
import { useIsInViewport } from '@hooks/useIsInViewport'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import StrapiImage from '@templates/strapi-cms/content-types/Image'

const GridWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  gap: '15px',
  aspectRatio: '1.36',
  width: '100%',
}))

const GridItem = styled('div')(({ theme }) => ({
  gridRow: 'span 1',
  gridColumn: 'span 1',
  '&:hover, &:focus': {
    '& .tile-title-text': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      outline: 'none !important',
    },
  },
  '& a:focus': {
    outline: 'none !important',
  },
  '&.col-span-1': {
    gridColumn: 'span 1',
  },
  '&.col-span-full': {
    gridColumn: 'span 2',
  },
  '&.row-span-1': {
    gridRow: 'span 1',
  },
  '&.row-span-full': {
    gridRow: 'span 2',
  },
  '&.col-start-2': {
    gridRowStart: 1,
    gridRowEnd: 3,
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
}))

const ImgWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& img': {
    borderRadius: '2px',
  },
}))

const TitleText = styled('div')(({ theme, titleStyles }) => ({
  fontSize: '14px',
  lineHeight: 'normal',
  marginTop: '5px',
  color: '#333',
  fontWeight: 500,
  textAlign: 'left',
}))

const VARIANTS = {
  DefaultFourSquares: {
    maxItems: 4,
  },
  OneVerticalLarge_TwoSmallStacked: {
    maxItems: 3,
    classNames: {
      0: 'col-span-1 row-span-full',
    },
  },
  OneHorizontalLarge_TwoSmallSidebySide: {
    maxItems: 3,
    classNames: {
      0: 'col-span-full row-span-1',
    },
  },
  TwoSmallSidebySide_OneHorizontalLarge: {
    maxItems: 3,
    classNames: {
      2: 'col-span-full row-span-1',
    },
  },
  TwoSmallStacked_OneVerticalLarge: {
    maxItems: 3,
    classNames: {
      2: 'col-span-1 col-start-2',
    },
  },
}

const RightSideBanners = ({ bannerGroup = null, onClose }) => {
  const bannerRef = useRef()
  const isBannerInViewport = useIsInViewport(bannerRef.current)

  if (!bannerGroup) {
    return null
  }

  const variant = bannerGroup?.Variant || 'DefaultFourSquares'
  const banners = (bannerGroup?.CategoryBanners || []).slice(0, VARIANTS[variant].maxItems)

  return (
    <GridWrapper ref={bannerRef}>
      {banners.map(({ CategoryBanner }, i) => {
        const image = CategoryBanner?.Image
        const link = CategoryBanner?.CategoryLink?.Link
        return (
          // eslint-disable-next-line react/no-array-index-key
          <GridItem key={`${image?.id}}-${i}}`} className={VARIANTS[variant].classNames?.[i]}>
            {isBannerInViewport && (
              <StrapiLink
                data={{ ...link }}
                trackingData={{
                  category: 'navigation',
                  action: 'link click',
                  label: link?.Title || link?.DisplayText,
                }}
                aria-hidden="true"
                tabIndex="-1"
                disableUnderline
                onClick={onClose}
              >
                <ImgWrapper>
                  <StrapiImage
                    data={{
                      desktop: CategoryBanner?.Image,
                      alt: link?.DisplayText,
                    }}
                    noPaddingBottom
                  />
                  <TitleText className="tile-title-text">{link?.DisplayText}</TitleText>
                </ImgWrapper>
              </StrapiLink>
            )}
          </GridItem>
        )
      })}
    </GridWrapper>
  )
}

RightSideBanners.propTypes = {
  bannerGroup: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default RightSideBanners
