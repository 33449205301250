/* eslint-disable import/no-unresolved */
import React from 'react'
import { Button, Typography, styled, useTheme, useMediaQuery } from '@mui/material'
import BaseSwiper from '@rtg2022/components/organisms/BaseSwiper/BaseSwiper'
import useStaticNavigationQuery from '@hooks/useStaticNavigationQuery'

const PillWrapper = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  backgroundColor: `${theme.palette.primary.main}0D`,
  borderRadius: '40px',
}))

const NavPill = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.primary.dark,
}))

const StyledSwiper = styled(BaseSwiper)(({ theme }) => ({
  padding: '16px 0 8px 16px',
  '& .swiper-slide': {
    width: 'max-content !important',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const NavPillsSwiper = () => {
  const navigation = useStaticNavigationQuery()

  const slides = navigation.map(({ HeaderNavLinks }) => {
    const link = HeaderNavLinks.PrimaryLink.Link
    return (
      <PillWrapper key={link.Title} variant="text" href={link.InternalUrl}>
        <NavPill>{link.DisplayText}</NavPill>
      </PillWrapper>
    )
  })

  return (
    <>
      <StyledSwiper slides={slides} spaceBetween={8} freeModeMobile hideScrollbar />
    </>
  )
}

export default NavPillsSwiper
