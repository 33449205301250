// dependencies
import React, { useRef, useState } from 'react'
import { string } from 'prop-types'
import { Menu, Skeleton, Typography, Box, styled, Paper } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LocationIcon from '@mui/icons-material/LocationOnOutlined'
import LocationActiveIcon from '@mui/icons-material/LocationOn'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { announce } from '@helpers/aria-announce'

// hooks
import { useMyStore } from '@hooks/useMyStore'
import { useSelector } from 'react-redux'

// components
import DeliveryInfo from '@components/shared/ShopAndDeliverTo/index'
import { NavButton } from './desktop/Styled'
import StoreChange from './store-change'

const BUTTON_ID = 'header-shop-deliver-menu-btn'
const MENU_ID = 'header-shop-deliver-menu'

const StyledMenu = styled(Menu)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '& .MuiPaper-root': {
      width: '100%!important',
    },
  },
  '& .MuiPaper-root': {
    border: 'none',
  },
}))

const StyledNavButton = styled(NavButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    borderRadius: '50%',
    marginLeft: '0!important',
    minWidth: '48px',
  },
}))

const StyledTextWrapper = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  textDecoration: 'none',
  '&>span:first-of-type': {
    color: '#333333',
    fontSize: 12,
    fontWeight: '600',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none!important',
  },
}))

const StyledIconContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  svg: {
    width: '32px',
    height: '32px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}))

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none!important',
  },
}))

const StyledMenuContent = styled('div')(({ theme }) => ({
  padding: '1rem',
  // TODO Change to theme color
  backgroundColor: '#F5F8FB',
  width: '388px',
  [theme.breakpoints.down('md')]: {
    width: 'unset',
  },
}))

const StyledPaper = styled(Paper)({
  border: '1px solid rgba(0, 0, 0, 0.12) !important',
})

const StyledDeliveryInfo = styled(DeliveryInfo)(({ theme }) => ({
  marginBottom: '0px',
  '&>*': {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
}))

const ShopAndDeliverTo = ({ className }) => {
  const { myStore, renderMyStoreModalLocator, mapUiDrawerIsOpen, setMapUiDrawerIsOpen } = useMyStore()
  const shippingAddress = useSelector(s => s?.location?.shipping_address)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const onClickOpen = () => {
    addToDataLayer_nextgen('header_click', {
      header_link: 'your store',
      your_store: myStore.storeName,
    })
    announce('Delivery location menu open')
    setOpen(true)
  }
  const onCloseMenu = () => setOpen(false)

  const onOpenUiDrawer = () => {
    setMapUiDrawerIsOpen(true)
  }

  const endIconStyles = { fontSize: '1.4rem' }

  const buttonAriaProps = {
    'aria-haspopup': 'true',
    'aria-label': `${myStore?.auto ? 'Your Closest Store - ' : 'Deliver To - '}${
      !myStore?.storeNumber ? shippingAddress : myStore?.storeExtendedLocationAndName
    } - Change Shop and Deliver to location`,
    ...(open
      ? {
          'aria-controls': MENU_ID,
          'aria-expanded': 'true',
        }
      : {}),
  }

  return (
    <>
      <StyledNavButton
        className={className}
        sx={{ justifyContent: 'flex-start' }}
        size="medium"
        ref={anchorRef}
        id={BUTTON_ID}
        data-testid="changeShopDeliverToLocation"
        endIcon={<StyledKeyboardArrowDownIcon sx={endIconStyles} />}
        onClick={onClickOpen}
        keepText
        {...buttonAriaProps}
      >
        <StyledIconContainer>{open ? <LocationActiveIcon /> : <LocationIcon />}</StyledIconContainer>
        <StyledTextWrapper className="text-wrapper">
          <span className="btn-text">{myStore?.auto ? 'YOUR CLOSEST STORE' : 'DELIVER TO'}</span>
          {typeof window !== 'undefined' ? (
            <span className="btn-text">
              {!myStore?.storeNumber ? shippingAddress : myStore?.storeExtendedLocationAndName}
            </span>
          ) : (
            <Skeleton className="btn-text" variant="text" />
          )}
        </StyledTextWrapper>
      </StyledNavButton>

      <StyledMenu
        open={open}
        onClose={onCloseMenu}
        anchorEl={anchorRef.current}
        disableEnforceFocus={mapUiDrawerIsOpen}
        MenuListProps={{
          'aria-labelledby': BUTTON_ID,
          as: 'div',
        }}
      >
        <StyledMenuContent>
          <StyledPaper elevation={0} sx={{ padding: '1rem' }}>
            <StyledDeliveryInfo defaultOpened />
          </StyledPaper>
          <StoreChange myStore={myStore} onChangeStore={onOpenUiDrawer} />
        </StyledMenuContent>
      </StyledMenu>
      {myStore?.auto && renderMyStoreModalLocator()}
    </>
  )
}

ShopAndDeliverTo.propTypes = {
  className: string,
}

export default ShopAndDeliverTo
