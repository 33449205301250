import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Menu, MenuItem, ListItemIcon, MenuList } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PersonIcon from '@mui/icons-material/Person'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import LoginIcon from '@mui/icons-material/LoginOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'
import { useModalsContext } from '@context/modalContext'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'

import { NavButton } from '../Styled'

const MENU_ID = 'header-accounts-menu'
const BUTTON_ID = 'header-accounts-menu-btn'

const createOnClickHandler = (name, callback = () => {}) => () => {
  addToDataLayer_nextgen('account_link_click', {
    account_link_name: name,
    account_link_location: 'header',
  })
  callback()
}

const LoggedOutMenu = ({ onLogIn }) => (
  <MenuList sx={{ minWidth: 220 }}>
    <MenuItem onClick={createOnClickHandler('Log In', onLogIn)}>
      <ListItemIcon>
        <LoginIcon />
      </ListItemIcon>
      Log in or Create Account
    </MenuItem>

    <MenuItem
      component={ButtonLink}
      data={{
        InternalUrl: '/order/status',
        Title: 'Order status',
      }}
      onClick={createOnClickHandler('Orders')}
    >
      <ListItemIcon>
        <LocalOfferIcon />
      </ListItemIcon>
      Orders
    </MenuItem>
  </MenuList>
)

LoggedOutMenu.propTypes = {
  onLogIn: PropTypes.func.isRequired,
}

const LoggedInMenu = ({ onLogOut }) => (
  <MenuList sx={{ minWidth: 220 }}>
    <MenuItem
      component={ButtonLink}
      data={{
        InternalUrl: '/account/profile',
        title: 'My Profile',
      }}
      onClick={createOnClickHandler('Account')}
    >
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      My Profile
    </MenuItem>

    <MenuItem
      component={ButtonLink}
      onClick={createOnClickHandler('Orders')}
      data={{
        InternalUrl: '/account/order',
        title: 'Order status',
      }}
    >
      <ListItemIcon>
        <LocalOfferIcon />
      </ListItemIcon>
      Orders
    </MenuItem>

    <MenuItem onClick={createOnClickHandler('Log Out', onLogOut)}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      Log out
    </MenuItem>
  </MenuList>
)
LoggedInMenu.propTypes = {
  onLogOut: PropTypes.func.isRequired,
}

const MyAccountDropDown = ({ user, data }) => {
  const anchorEl = useRef(null)
  const [open, setOpen] = useState(false)
  const { actions } = useModalsContext()

  const handleClick = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const onLogOut = () => {
    handleClose()
    actions.logout()
  }

  const onLogIn = () => {
    handleClose()
    actions.login()
  }

  const endIconStyles = { fontSize: '1.4rem' }
  const startIconStyles = { fontSize: '1.5rem !important' }

  const buttonAriaProps = {
    'aria-haspopup': 'true',
    'aria-label': user && data ? `Hello ${data?.contact?.firstName}` : 'Log in or Create Account',
    ...(open
      ? {
          'aria-controls': MENU_ID,
          'aria-expanded': 'true',
        }
      : {}),
  }

  return (
    <>
      <NavButton
        size="small"
        id={BUTTON_ID}
        endIcon={<KeyboardArrowDownIcon sx={endIconStyles} />}
        startIcon={
          user ? <AccountCircleIcon sx={startIconStyles} /> : <AccountCircleOutlinedIcon sx={startIconStyles} />
        }
        ref={anchorEl}
        onClick={handleClick}
        {...buttonAriaProps}
      >
        {user && data ? (
          <span className="text-wrapper">
            <span className="btn-text">Hello</span>
            <span className="btn-text underline">{data?.contact?.firstName}</span>
          </span>
        ) : (
          <span className="text-wrapper">
            <span className="btn-text underline">Log in or</span>
            <span className="btn-text underline">Create Account</span>
          </span>
        )}
      </NavButton>
      <Menu
        id={MENU_ID}
        anchorEl={anchorEl.current}
        open={open}
        MenuListProps={{
          'aria-labelledby': BUTTON_ID,
        }}
        onClose={handleClose}
      >
        {user ? <LoggedInMenu onLogOut={onLogOut} /> : <LoggedOutMenu onLogIn={onLogIn} />}
      </Menu>
    </>
  )
}

MyAccountDropDown.propTypes = {
  user: PropTypes.any,
  data: PropTypes.any,
}

export default MyAccountDropDown
